<template>
  <div class="apply-result">
    <div class="main">
      <i></i>
      <h3>提交成功</h3>
      <p>
        我们已收到您提交的食神通入驻申请，请耐心等待审核结果，我们将会在7天内向您的邮箱发送审核结果反馈，请注意查收！
      </p>
      <b @click="goUser">确定</b>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {}
  },
  methods: {
    goUser () {
      this.$router.push({ name: 'users' })
    }
  }
}
</script>

<style lang="less" scoped>
  @import "./index";
</style>
